.m-b-20 {
    margin-bottom: 20px;
}

.control-label .required, .form-group .required {
    padding-left: 15px;
    color: #333;
    font-size: 14px;
}

.nowrap {
    width: 1%;
    white-space: nowrap;
}

.clickable {
    cursor: pointer;
}

.portlet > .portlet-title > .nav-tabs {
    float: left !important;
}

.m-r-15 {
    margin-right: 15px;
}

.m-t-5 {
    margin-top: 5px;
}

.page-header.navbar .page-logo .logo-default {
    height: 50px;
    margin: 10px 55px 0 !important;
}

.table-striped > tbody > tr:nth-of-type(2n+1) {
    background-color: #d5d5d5;
}

.add-syndic-button {
    cursor: pointer;
}

table.dataTable td.sorting_1, table.dataTable td.sorting_2, table.dataTable td.sorting_3, table.dataTable th.sorting_1, table.dataTable th.sorting_2, table.dataTable th.sorting_3 {
    background: none!important;
}

#lodger-table_info {
    display: none;
}

#lodger-table_filter input {
    border: 1px solid #cccccc;
    padding: 5px;
}